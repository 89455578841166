import React from 'react'
import { objectOf, any, func, shape, instanceOf } from 'prop-types'
import {
  AfterComponentWrapper,
  AfterComponentLabel,
} from '@components/checkout/checkout-parts/payment-section/finance-plan/styles'
import Input from '@shared/input'

const SynchronyFinancePlan = ({ plan, setFinancePlan, financePlanRef }) => (
  <Input
    type="radio"
    field={plan?.siteFriendlyLabel}
    id={plan?.name}
    label={plan?.siteFriendlyLabel}
    info={plan}
    setInfo={setFinancePlan}
    name="finance"
    radioType
    radioValue={plan?.financeCode}
    afterComponent={
      <AfterComponentWrapper>
        <AfterComponentLabel>{plan?.siteFriendlyLabel}</AfterComponentLabel>
      </AfterComponentWrapper>
    }
    parentRef={financePlanRef}
  />
)

SynchronyFinancePlan.propTypes = {
  plan: objectOf(any),
  setFinancePlan: func.isRequired,
  financePlanRef: shape({
    current: instanceOf(any),
  }),
}

SynchronyFinancePlan.defaultProps = {
  plan: {},
}

export default SynchronyFinancePlan
