import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardContent, CardHeader, HeaderContent, HeaderNumber } from '@shared/card'
import { setOrder, setPaymentsApplied, setOrderList, setOrderNumber } from '@redux/modules/orderStatus'
import { LoadingSpinner, LoadingSpinnerWrapper } from '@components/shared/LoadingSpinner'
import Billing from './billing/index'
import { OrderInformation, StyledHorizontalRule, StyledOrderPaymentWrapper, StyledOrderPayment } from './styles'
import Delivery from './delivery/delivery'
import Review from './review'
import { getOrderOnRefresh } from './helpers'

const OrderPayment = () => {
  const [order, setLocalOrder] = useState(null)
  const orderNumber = useSelector(state => state.orderStatus.orderNumber)
  const orderList = useSelector(state => state.orderStatus.orderList)
  const dispatch = useDispatch()

  // add all received deposits as applied payments, or default to 0
  const paymentsApplied = useSelector(state => state.orderStatus.paymentsApplied)

  useEffect(() => {
    const newOrder = orderList.filter(orderItem => orderItem.OrderNumber === orderNumber)[0]
    return setLocalOrder(newOrder)
  }, [orderList, orderNumber])

  useEffect(() => {
    if (order?.Deposits?.Deposit) {
      dispatch(setPaymentsApplied(order.Deposits.Deposit))
    }
    if (order?.FinDeposits?.Deposit) {
      dispatch(setPaymentsApplied(order?.FinDeposits?.Deposit))
    }
  }, [dispatch, order])

  useEffect(() => {
    // * On Refresh we want to check for order number and call api to update orderList
    if (!orderList?.length && !orderNumber) {
      getOrderOnRefresh({ dispatch, setOrder, setOrderList, setOrderNumber })
    }
  }, [dispatch, orderList, orderNumber])

  return (
    <>
      {order && (
        <StyledOrderPaymentWrapper>
          <div className="order-payment grid-container">
            <div className="cell small-12 grid-x">
              <StyledOrderPayment>
                <Card>
                  <CardHeader>
                    <HeaderNumber headerNumber="1" />
                    <HeaderContent>ORDER INFORMATION</HeaderContent>
                  </CardHeader>
                  <CardContent>
                    <OrderInformation>
                      <div>
                        <span>Order #</span>
                        <span>{order?.OrderNumber || ''}</span>
                      </div>
                      <div>
                        <div>
                          <span>Order Total: </span>
                          <span>{`$${order?.OrderTotal}`}</span>
                        </div>
                        <div>
                          <span>Payments Applied: </span>
                          <span>{`$${paymentsApplied.toFixed(2)}`}</span>
                        </div>
                        <StyledHorizontalRule />
                        <div>
                          <span>Balance Due: </span>
                          <span>{`$${order?.Balance}`}</span>
                        </div>
                      </div>
                    </OrderInformation>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <HeaderNumber headerNumber="2" />
                    <HeaderContent>DELIVERY</HeaderContent>
                  </CardHeader>
                  <CardContent>
                    <Delivery order={order} />
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <HeaderNumber headerNumber="3" />
                    <HeaderContent>PAYMENT</HeaderContent>
                  </CardHeader>
                  <CardContent>
                    <Billing
                      balance={order?.Balance}
                      shippingAddress={{
                        FirstName: order?.Customer?.FirstName,
                        LastName: order?.Customer?.LastName,
                        ...order?.DeliveryAddress,
                      }}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <HeaderNumber headerNumber="4" />
                    <HeaderContent>REVIEW PAYMENT</HeaderContent>
                  </CardHeader>
                  <CardContent>
                    <Review order={order} />
                  </CardContent>
                </Card>
              </StyledOrderPayment>
            </div>
          </div>
        </StyledOrderPaymentWrapper>
      )}
      {!orderList?.length && !orderNumber && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      )}
    </>
  )
}

OrderPayment.propTypes = {}

export default OrderPayment
