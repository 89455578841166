import React from 'react'
import { store } from '@redux/store'
import { getUrlParams } from '@helpers/string-helper'
// import { setOrderInfo } from '@helpers/checkout/global'
// import { validateRTGCreditInfo } from '@helpers/checkout/payment-section/rtg-finance'
import { bool, func, number, object } from 'prop-types'
import loaderLight from '@assets/images/loader-light.svg'
import { getFinancePlansFromRegion } from '@helpers/finance'
import { setFinancePlan } from '@components/checkout/checkout-parts/payment-section/finance-plan/helpers'
// import { fetchCreditPostback } from '@services/credit'
import ErrorMessage from '@shared/error-message'
import { generateErrorMessage } from '@helpers/errors'
import SynchronyLogo from '@shared/svgs/synchrony-logo'
import GenesisLogo from '@shared/svgs/genesis-logo'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import { Link } from 'gatsby'
import CheckoutInput from '@components/checkout/checkout-parts/checkout-input'
// import { HardLine } from './DropdownSlider/styles'
// import SynchronyApply from './synchrony-apply'
// import SynchronyApply from '@components/checkout/checkout-parts/payment-section/synchrony-apply'
import AcknowledgeCheckBoxes from '@components/checkout/checkout-parts/payment-section/finance-plan/acknowledge-checkboxes'
// import SelectedPlan from '@components/checkout/checkout-parts/payment-section/finance-plan/selected-plan'
import SynchronyFinancePlan from '@shared/finance/synchrony-finance-plan-option'
import DropdownSlider from '@components/checkout/checkout-parts/payment-section/DropdownSlider'
import MaskedCredit from '@components/checkout/masked-credit'
import { balanceInquiry } from '@services/order-status'
import {
  initialCreditcardPayment,
  initialFinancePayment,
  setCreditCardPayment,
  setFinancePayment,
  setPartialPayment,
} from '@redux/modules/orderStatus'

export const FullWidthCentered = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const SubmitButtonContainer = styled.section`
  width: 100%;
  text-align: left;
  @media (max-width: ${breakPoints.small}) {
    text-align: center;
  }
`

export const SubmitRtgButton = styled.button`
  min-width: 9em;
  margin-right: 4%;
  margin-top: 0.5em;
  background-color: ${({ disabled }) => (disabled ? colors.disabled : colors.primary)};
  border-radius: 0;
  color: ${colors.primaryInvert};
  border: 0px;
  padding: 15px;
  text-align: center;
  box-sizing: border-box;
  font-size: ${fonts.txtMedium};
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? colors.disabled : colors.lightBlue)};
  }
  @media (max-width: ${breakPoints.small}) {
    margin-right: 0;
  }
`

export const CreditOptionsButton = styled(Link)`
  background-color: ${colors.primary};
  border-radius: 0;
  color: ${colors.primaryInvert};
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  font-size: ${fonts.txtMedium};
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background-color: ${colors.lightBlue};
  }
`

export const LoadingSpinner = styled.img`
  height: 1.25rem;
  width: 1.25rem;
  margin: 0;
  float: unset;
`

export const LogoWrapper = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: 30px;
    margin: 0 10px;
  }
`
export const SelectedLogoWrapper = styled(LogoWrapper)`
  margin-top: 10px;
`

export const HardLine = styled.hr`
  width: 100%;
  height: 3px;
`

const PlanText = styled.p`
  font-size: ${fonts.txtMedium};
  margin: 10px 0;
`

const DetailsWrapper = styled.section`
  margin: 5px 0 10px;
  display: flex;

  > input {
    margin-left: 0;
  }
`

const GENESIS = 'GENESIS'
const SYNCHRONY = 'Synchrony'

class RTGCredit extends React.Component {
  constructor(props) {
    super(props)
    this.financeCard = React.createRef()
    this.acknowledge = React.createRef()
    this.terms = React.createRef()
    this.financePlan = React.createRef()
    this.financePlan2 = React.createRef()
    this.phoneNumber = React.createRef()

    this.state = {
      creditCardNumber: '',
      cardProvider: '',
      checkedPlan: false,
      financeApproval: {
        provider: '',
        status: '',
      },
      financePlan: {},
      genesisAttempts: 0,
      hasFinance: false,
      invalidFields: [],
      loading: false,
      paymentInfo: [],
      phoneNumber: '',
      // postbackRefId: null,
      rtgCreditInfo: {
        acknowledge: false,
        terms: false,
      },
    }
  }

  async componentDidMount() {
    const { paymentInfo } = this.state

    if (paymentInfo) {
      paymentInfo.forEach(payment => {
        if (payment.paymentType === 'GEN' || payment.paymentType === 'FIN' || payment.paymentType === 'DBUY') {
          this.setState({ creditCardNumber: payment.paymentProperties.accountNumber, hasFinance: true })
        }
      })
    }

    // await fetchCreditPostback().then(data => this.setState({ postbackRefId: data.postbackRefId }))

    if (window !== undefined) {
      const financeApproval = getUrlParams(window.location.search)

      if (financeApproval.status) {
        this.setState({ financeApproval })
      }
    }
  }

  validateRTGCreditInfo(plan, rtgCreditInfo) {
    const { paymentInfo } = this.state
    const entries = Object.entries(rtgCreditInfo)
    const invalidFields = []
    for (let i = 0; i < entries.length; i++) {
      const [entryKey, entryData] = entries[i]
      if (entryData === '') {
        if (plan?.financeCode === 'GENESIS' && entryKey === 'phoneNumber') {
          invalidFields.push(entryKey)
        } else if (entryKey !== 'phoneNumber') {
          invalidFields.push(entryKey)
        }
      } else if (entryKey === 'finance' && (entryData.length < 16 || Number.isNaN(entryData.toString()))) {
        invalidFields.push('finance')
      } else if (entryKey === 'acknowledge' && !entryData) {
        invalidFields.push('acknowledge')
      } else if (entryKey === 'terms' && !entryData) {
        invalidFields.push('terms')
      }
    }
    if (invalidFields.length < 1) {
      try {
        let rtgPaymentInfo = []
        const giftCards = paymentInfo.filter(payment => payment.paymentType === 'GIFT')
        if (giftCards.length > 0) {
          rtgPaymentInfo = giftCards
        }
        rtgPaymentInfo.push({
          paymentType: plan?.financeCode === 'GENESIS' ? 'GEN' : 'FIN',
          paymentProperties: {
            accountNumber: rtgCreditInfo.cardNumber,
            financePlan: plan?.financeCode,
            encryptedFinancePlans: plan?.encryptedFinanceCodes,
            zip: rtgCreditInfo.zip,
            hasPayments: plan?.downPaymentRequired,
            phoneNumber: rtgCreditInfo.phoneNumber,
          },
        })
        this.setState({
          rtgCreditInfo: {
            acknowledge: false,
            terms: false,
            success: true,
            loading: false,
          },
          paymentInfo: rtgPaymentInfo,
          invalidFields,
          hasFinance: true,
        })
      } catch (err) {
        this.setState({
          invalidFields: [...invalidFields, 'finance'],
          loading: false,
        })
      }
    }
  }

  setCreditCardNumber = value => {
    const { disableGenesis } = this.props
    let cardProvider = ''
    if (value?.length === 16) {
      const firstDigit = value.slice(0, 1)
      if (firstDigit === '6') {
        // this.setState({
        //   acknowledge: false,
        // })
        cardProvider = SYNCHRONY
      } else if (firstDigit === '7') {
        if (disableGenesis) {
          cardProvider = ''
        } else {
          cardProvider = GENESIS
        }
        // this.setState({
        //   acknowledge: true,
        // })
      }
      setTimeout(() => {
        if (this.financePlan?.current?.checked === false) {
          if (document.getElementById(this.financePlan.current.id)) {
            document.getElementById(this.financePlan.current.id).click()
          }
        }
      }, 500)
      this.setState({ creditCardNumber: value, cardProvider, invalidFields: [], checkedPlan: true })
    }
    // this.setState({
    //   acknowledge: false,
    //   terms: false,
    // })

    this.setState({ creditCardNumber: value, cardProvider })
  }

  trimCreditCard = card => card.replace(/\s/g, '').trim()

  hasInvalidFields = () => {
    const { rtgCreditInfo, cardProvider, checkedPlan, creditCardNumber, genesisAttempts } = this.state
    const invalidFields = []
    let attempts = genesisAttempts

    if (cardProvider === SYNCHRONY && checkedPlan === false) {
      invalidFields.push('financePlan')
    }
    if (rtgCreditInfo.acknowledge === false) invalidFields.push('acknowledge')
    if (rtgCreditInfo.terms === false) {
      if (cardProvider === 'GENESIS') {
        invalidFields.push('genesis-terms')
      } else invalidFields.push('terms')
    }
    if (cardProvider === 'GENESIS' && !invalidFields.includes('genesis-terms')) {
      attempts += 1
    }
    if (attempts === 3) {
      invalidFields.push('genesis-attempts')
    }
    if (this.trimCreditCard(creditCardNumber).length !== 16 && attempts !== 3) {
      invalidFields.push('finance')
    }

    this.setState({ invalidFields, genesisAttempts: attempts })

    return invalidFields.length > 0
  }

  createErrorMessage = (field, index) => {
    const errorMessage = generateErrorMessage(field)

    if (index === 0) {
      switch (field) {
        case 'finance':
          if (this.financeCard.current.value.length === 16) break
          this.financeCard.current.focus()
          break
        case 'terms':
          if (this.terms.current.checked === true) break
          this.terms.current.focus()
          break
        case 'acknowledge':
          if (this.acknowledge.current.checked === true) break
          this.acknowledge.current.focus()
          break
        case 'financePlan':
          if (this.financePlan.current.checked === true || this.financePlan2.current.checked === true) {
            break
          }
          this.financePlan.current.focus()
          break
        default:
          break
      }
    }
    return errorMessage
  }

  getLogos = () => {
    const { cardProvider } = this.state
    switch (cardProvider) {
      case GENESIS:
        return <GenesisLogo />
      case SYNCHRONY:
        return <SynchronyLogo />
      default:
        return <SynchronyLogo />
      // default:
      //   return (
      //     <>
      //       <SynchronyLogo style={{ height: '60px' }} /> <p>|</p> <GenesisLogo />
      //     </>
      //   )
    }
  }

  render() {
    const {
      creditCardNumber,
      cardProvider,
      errorMsg,
      financeApproval,
      financePlan,
      genesisAttempts,
      hasFinance,
      invalidFields,
      loading,
      phoneNumber,
      // postbackRefId,
      rtgCreditInfo,
    } = this.state
    const { authAmount, onSubmit, minimumDisabled, financePlans, synchronyPlan } = this.props
    const currentPlan = financePlans.filter(plan => plan?.financeCode === financePlan?.code)[0]
    // const financePayments = order.paymentInfo.filter(
    //   payment => payment.paymentType === 'FIN' || payment.paymentType === 'GEN',
    // )[0]
    // const genesisPlan = financePlans.filter(plan => plan?.financeCode === 'GENESIS')[0]
    const isGenesis = cardProvider !== GENESIS
    return (
      <>
        <DropdownSlider title="Rooms To Go Credit Provided By" showTitle={isGenesis}>
          {hasFinance ? (
            <>
              {/* <SelectedLogoWrapper>{this.getLogos()}</SelectedLogoWrapper> */}
              <PlanText>
                Account: <MaskedCredit lastFour={creditCardNumber.slice(12)} />
              </PlanText>
              <SubmitButtonContainer>
                <SubmitRtgButton
                  type="button"
                  value="Remove Financing"
                  onClick={async event => {
                    event.preventDefault()
                    store.dispatch(setCreditCardPayment(initialCreditcardPayment))
                    store.dispatch(setFinancePayment(initialFinancePayment))
                    store.dispatch(setPartialPayment({ partialPaymentValue: '0', isFullPayment: true }))
                    if (document.getElementById('tab1')) document.getElementById('tab1').click()
                  }}
                >
                  Remove Financing
                </SubmitRtgButton>
              </SubmitButtonContainer>
            </>
          ) : (
            <>
              {
                /* {cardProvider !== GENESIS && <SynchronyApply postbackRefId={postbackRefId} />} */
                <br />
              }
              {/* <LogoWrapper>{this.getLogos()}</LogoWrapper> */}
              {errorMsg && <ErrorMessage customMessage={{ message: errorMsg, id: 'finance-error' }} />}
              {invalidFields.length > 0 &&
                invalidFields.map((field, index) => (
                  <ErrorMessage
                    key={field}
                    customMessage={this.createErrorMessage(field, index)}
                    invalidFields={[field]}
                  />
                ))}
              {financeApproval.status === 'approved' || financeApproval.status.length === 0 ? (
                <div style={{ width: '300px' }}>
                  <CheckoutInput
                    type="text"
                    field="finance"
                    id="credit-card"
                    label="Account Number*"
                    info={creditCardNumber}
                    setInfo={this.setCreditCardNumber}
                    name="credit-card"
                    placeholder="xxxx xxxx xxxx xxxx"
                    parentRef={this.financeCard}
                    disabled={genesisAttempts === 3}
                  />
                </div>
              ) : (
                <FullWidthCentered>
                  <CreditOptionsButton
                    to="/credit-options"
                    onClick={() => {
                      // TODO: navigation need to add code to track ga data
                    }}
                  >
                    Credit Options
                  </CreditOptionsButton>
                </FullWidthCentered>
              )}
              {/* {cardProvider === 'GENESIS' && (
                <CheckoutInput
                  type="text"
                  field="finance"
                  id="phone"
                  label="Phone Number Associated with your Genesis Credit Account*"
                  info={phoneNumber}
                  setInfo={value => this.setState({ phoneNumber: value })}
                  name="phoneNumber"
                  placeholder=""
                  parentRef={this.phoneNumber}
                  disabled={genesisAttempts === 3}
                />
              )} */}
              {cardProvider && (
                <>
                  <section>
                    {cardProvider === 'Synchrony' && (
                      <DetailsWrapper key={synchronyPlan?.name ?? cardProvider}>
                        <SynchronyFinancePlan
                          plan={synchronyPlan}
                          setFinancePlan={() =>
                            this.setState({
                              financePlan: {
                                code: synchronyPlan?.financeCode,
                                hasPayment: true,
                                encryptedFinancePlans: synchronyPlan?.encryptedFinanceCodes,
                              },
                            })
                          }
                          financePlanRef={this.financePlan}
                        />
                      </DetailsWrapper>
                    )}
                  </section>
                  <AcknowledgeCheckBoxes
                    rtgCreditInfo={rtgCreditInfo}
                    setRTGCreditInfo={(info, field) =>
                      this.setState(prevState => ({
                        rtgCreditInfo: {
                          ...prevState.rtgCreditInfo,
                          [field]: info,
                        },
                      }))
                    }
                    financePlan={currentPlan}
                    termsRef={this.terms}
                    acknowledgeRef={this.acknowledge}
                    cardProvider={cardProvider}
                  />
                  {minimumDisabled && <ErrorMessage customMessage={{ message: `*Minimum partial payment is $20.` }} />}
                  <SubmitButtonContainer>
                    <SubmitRtgButton
                      type="button"
                      value="Submit"
                      onClick={async event => {
                        event.preventDefault()
                        if (!this.hasInvalidFields()) {
                          this.setState({
                            loading: true,
                          })

                          const cardInfo = await balanceInquiry({
                            accountNumber: creditCardNumber,
                            transactionID: store.getState().orderStatus.order.OrderNumber,
                          }).catch(() => {
                            this.setState({
                              errorMsg: 'There was an error getting your account info. Please try again',
                              loading: false,
                            })
                            return false
                          })

                          let availableAmount = 0
                          const isFullPayment = () => store.getState().orderStatus.isFullPayment
                          const partialPaymentValue = () => store.getState().orderStatus.partialPaymentValue
                          const newAmount = (!isFullPayment() && partialPaymentValue()) || authAmount

                          if (cardInfo.availableCredit < newAmount) {
                            if (cardInfo.availableCredit === 0) {
                              this.setState({
                                errorMsg: 'Your account balance is 0, please try another payment',
                                loading: false,
                              })
                            } else if (!isFullPayment()) {
                              availableAmount = partialPaymentValue()
                            } else {
                              store.dispatch(
                                setPartialPayment({
                                  isFullPayment: false,
                                  partialPaymentValue: cardInfo.availableCredit.toString(),
                                }),
                              )
                              availableAmount = cardInfo.availableCredit
                            }
                          } else {
                            availableAmount = newAmount
                          }

                          if (cardInfo.availableCredit > 0) {
                            if (financePlan) setFinancePlan(financePlan.code)
                            onSubmit({
                              accountNumber: creditCardNumber,
                              authAmount: Number(availableAmount),
                              encryptedFinancePlans: financePlan?.encryptedFinancePlans,
                              uvFinancePlan: financePlan?.code?.split('|')[0],
                            })

                            this.validateRTGCreditInfo(currentPlan, {
                              financePlan: financePlan?.code,
                              cardNumber: this.trimCreditCard(creditCardNumber),
                              hasPayment: financePlan?.hasPayment,
                              phoneNumber: phoneNumber?.replace(/[-.()]/g, ''),
                            })
                          } else {
                            this.setState({
                              errorMsg: 'Your account balance is 0, please try another payment',
                              loading: false,
                            })
                          }
                        }
                      }}
                      disabled={minimumDisabled || genesisAttempts === 3}
                    >
                      {!loading && 'Submit'}
                      {loading && <LoadingSpinner alt="Submitting rooms to go credit card" src={loaderLight} />}
                    </SubmitRtgButton>
                  </SubmitButtonContainer>
                </>
              )}
            </>
          )}
        </DropdownSlider>
        <HardLine />
      </>
    )
  }
}

RTGCredit.propTypes = {
  disableGenesis: bool,
  authAmount: number,
  onSubmit: func,
  minimumDisabled: bool,
  financePlans: object,
  synchronyPlan: object,
}

RTGCredit.defaultProps = {
  onSubmit: () => {},
}

export default RTGCredit
