import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { shape, bool, string, any, object, number } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { colors, fonts } from '@constants/styles'
import loaderLight from '@images/loader-light.svg'
import { PrimaryButton } from '@shared/button-types'
import ErrorMessage from '@shared/error-message'
import { currencyFormatUS } from '@helpers/string-helper'
import { setOrder, setOrderList, setOrderNumber } from '@redux/modules/orderStatus'
import { sentryLogger, levels, setExtra } from '@helpers/sentry-logger'
import ReviewSectionInvalidFields from '@components/checkout/checkout-parts/review-section/review-section-invalid-fields'
import ReviewSectionTermsConditions from '@components/checkout/checkout-parts/review-section/review-section-terms-conditions'
import { getOrder } from '@helpers/checkout/global'
import { createPaymentObject, submitOrderPayment } from '../helpers'
import '../review-section.sass'

const ReviewWrapper = styled.div`
  .cell {
    display: flex;
    flex-direction: column;

    section {
      display: flex;
      justify-content: space-between;
    }
  }
  .price-heading {
    font-weight: bold;
  }
  .deduction {
    color: ${colors.red};
    font-weight: bold;
  }

  .current-payment {
    font-weight: bold;
    font-size: ${fonts.txtMeduim};
  }

  button {
    width: 300px;
  }
  .customer-email {
    font-weight: bold;
    padding: 0 0 0 0.5em;
  }
  .existing-email {
    margin: 1em 0 1em 0;
  }
  .email-info {
    margin: 1em 0 1em 0;
    label {
      display: inline;
      &.email_addr {
        font-weight: bold;
      }
    }
    #email_addr {
      width: 100%;
      max-width: 400px;
      min-width: 250px;
      margin: 0 2.5% 2.5% 0;
      line-height: 20px;
      padding: 10px;
      border: 1px solid #dedede;
      box-sizing: border-box;
      display: block;
    }
    .customer-email {
      padding: 0.5rem 0;
      font-weight: bold;
      display: block;
    }
  }
`

const Loader = styled.img`
  height: 1rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Review = ({ order }) => {
  const dispatch = useDispatch()
  const [currentBalance, setCurrentBalance] = useState(0)
  const [paymentObject, setPaymentObject] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [paymentMsg, setPaymentMsg] = useState(false)
  const [emailMsg, setEmailMsg] = useState(false)
  const {
    partialPaymentValue,
    isFullPayment,
    premiumDelivery,
    premiumDeliveryCharge,
    paymentsApplied,
    creditcardPayment,
    financePayment,
  } = useSelector(state => state.orderStatus)
  const reviewDisabled = !paymentObject?.financePayment?.authAmount && !paymentObject?.creditcardPayment?.token

  useEffect(() => {
    if (paymentMsg && reviewDisabled) {
      setPaymentMsg(false)
    }
  }, [paymentMsg, reviewDisabled])

  useEffect(() => {
    if (order?.Balance && premiumDelivery) {
      const newBalance = Number(order.Balance) + Number(premiumDeliveryCharge?.total)
      setCurrentBalance(newBalance.toFixed(2))
    } else {
      setCurrentBalance(Number(order?.Balance).toFixed(2))
    }
  }, [order, premiumDelivery, premiumDeliveryCharge, premiumDeliveryCharge.total])

  useEffect(() => {
    if (order) {
      setPaymentObject(
        createPaymentObject({
          order,
          creditcardPayment,
          financePayment,
          premiumDelivery,
          DeliveryCharge: premiumDeliveryCharge,
        }),
      )
      window.sessionStorage.setItem('orderEmail', order.EmailAddress)
      window.sessionStorage.setItem('orderAddress', JSON.stringify(order.BillingAddress))
    }
  }, [order, financePayment, creditcardPayment, premiumDelivery, premiumDeliveryCharge])

  const resetErrorMsgs = () => {
    setPaymentMsg(false)
    setErrorMsg(false)
    setEmailMsg(false)
  }

  const handleInputChange = e => {
    paymentObject.customer.email = e.target.value
    window.sessionStorage.setItem('orderEmail', e.target.value)
  }

  return (
    <>
      {!reviewDisabled && (
        <ReviewWrapper className="grid-x cell small-12 medium-6 large-6">
          <div className="cell small-12 medium-6 large-6">
            <section>
              <p className="price-heading">Subtotal:</p>
              <p>
                {currencyFormatUS(
                  Number(order?.OrderTotal) - Number(order?.TaxAmount) - Number(order?.DeliveryCharge),
                  true,
                )}
              </p>
            </section>
            {(premiumDelivery || !order?.DoorwayDelivery) && (
              <section>
                <p className="price-heading">Delivery Charge:</p>
                <p>
                  {premiumDeliveryCharge.total
                    ? currencyFormatUS(premiumDeliveryCharge.total, true)
                    : order.DeliveryCharge || 'N/A'}
                </p>
              </section>
            )}
            <section>
              <p className="price-heading">Taxes:</p>
              <p>{currencyFormatUS(order?.TaxAmount, true)}</p>
            </section>
            <section>
              <p className="price-heading">Order Total:</p>
              <p>{currencyFormatUS(order?.OrderTotal, true)}</p>
            </section>
            <section>
              <p className="price-heading">Applied Payments:</p>
              <p className="deduction">-{currencyFormatUS(paymentsApplied, true)}</p>
            </section>
            <section>
              <p className="price-heading">Balance:</p>
              <p>{currencyFormatUS(currentBalance, true)}</p>
            </section>
            <hr />
            <section>
              <p className="current-payment">Payment Amount:</p>
              <p className="current-payment">
                {isFullPayment ? currencyFormatUS(currentBalance, true) : currencyFormatUS(partialPaymentValue, true)}
              </p>
            </section>
            {order?.EmailAddress !== '' ? (
              <section className="existing-email">
                <p>
                  Your receipt will be emailed to:
                  <span className="customer-email">{order?.EmailAddress}</span>
                </p>
              </section>
            ) : (
              <section className="new-email email-info">
                <label htmlFor="email_addr" className="email_addr">
                  Email address to send receipt*
                </label>
                <input
                  type="email"
                  id="email_addr"
                  name="email_addr"
                  placeholder=""
                  width="200"
                  onChange={e => handleInputChange(e)}
                  required
                  aria-required="true"
                />
              </section>
            )}
            <section>
              <form className="review-form">
                <p className="review-text">Please review your order above and make any changes you need.</p>
                {errorMsg && (
                  <ReviewSectionInvalidFields
                    invalidFields={[{ errorMsg }]}
                    reviewInfo={getOrder().reviewInfo.acceptTerms}
                  />
                )}
                {paymentMsg && (
                  <ErrorMessage
                    customMessage={{
                      message:
                        'There was a problem processing your payment, please try again or enter a different credit card or payment type.',
                    }}
                  />
                )}
                {emailMsg && (
                  <ErrorMessage
                    customMessage={{
                      message: 'A valid email address is required for orders.',
                    }}
                  />
                )}
                <ReviewSectionTermsConditions reviewInfo={getOrder().reviewInfo.acceptTerms} />
              </form>
            </section>
            <ButtonContainer>
              <PrimaryButton
                type="button"
                onClick={async () => {
                  resetErrorMsgs()
                  if (getOrder().reviewInfo.acceptTerms === false) {
                    setErrorMsg(true)
                  } else if (paymentObject.customer.email === '') {
                    setEmailMsg(true)
                  } else {
                    if (order) {
                      await setPaymentObject(
                        createPaymentObject({
                          order,
                          creditcardPayment,
                          financePayment,
                          premiumDelivery,
                          DeliveryCharge: premiumDeliveryCharge,
                        }),
                      )
                    }
                    submitOrderPayment(paymentObject, setLoading)
                      .then(() => {
                        // reset order state in redux to fetch latest for success page to show this payment
                        dispatch(setOrder({}))
                        dispatch(setOrderList([]))
                        dispatch(setOrderNumber(''))
                      })
                      .catch(error => {
                        setPaymentMsg(true)
                        setLoading(false)
                        sentryLogger({
                          configureScope: {
                            type: setExtra,
                            level: levels.fatal,
                          },
                          captureMessage: {
                            type: 'text',
                            message: `Pay Online - Payment Failure - ${error}`,
                            level: levels.fatal,
                          },
                        })
                      })
                  }
                }}
              >
                {!loading ? (
                  `Pay ${
                    isFullPayment ? currencyFormatUS(currentBalance, true) : currencyFormatUS(partialPaymentValue, true)
                  }`
                ) : (
                  <Loader alt="payment processing" src={loaderLight} />
                )}
              </PrimaryButton>
            </ButtonContainer>
          </div>
        </ReviewWrapper>
      )}
    </>
  )
}

Review.propTypes = {
  order: shape({
    AbleToReschedule: bool,
    AdditionalDirections: string,
    AffiliateData: string,
    Balanace: string,
    BillingAddress: object,
    Customer: object,
    DeliveryAddress: object,
    DeliveryCharge: number,
    DeliveryDate: string,
    DeliveryType: string,
    Deposits: object,
    Division: string,
    DoorwayDelivery: bool,
    EmailAddress: string,
    EstimatedDeliveryTimeFrame: string,
    FinDeposits: any,
    FinanceAccountNumber: string,
    FinancePlan: string,
    FraudFlag: string,
    GCDeposits: any,
    I95ind: bool,
    LineItems: object,
    OrderDate: string,
    OrderMessage: string,
    OrderTotal: number,
    PickupData: object,
    RemoteEstimatedDeliveryTimeFrame: string,
    ShipDate: string,
    Status: string,
    StoreNumber: string,
    TaxAmount: number,
    TaxAreaId: string,
    active: bool,
    dispatchTrack: string,
    orderKey: string,
    showPayonline: bool,
    upgradeDelivery: bool,
  }).isRequired,
}

export default Review
