import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { number, object } from 'prop-types'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import ReactToolTip from 'react-tooltip'
import { colors } from '@constants/styles'
import { getCreditCardDecision } from '@helpers/checkout/payment-section/credit-card'
import ErrorMessage from '@shared/error-message'
import RTGCredit from '@shared/rtg-credit'
import CreditCardForm from '@shared/credit-card-form'
import { getFinancePlansFromRegion } from '@helpers/finance'
import {
  initialCreditcardPayment,
  initialFinancePayment,
  setCreditCardPayment,
  setFinancePayment,
  // setPartialPayment,
} from '@redux/modules/orderStatus'
import BillingAddress from './billing-address'
import PaymentAmounts from './payment-amounts'
import { TabButtonContainer, TabButton } from '../styles'

const PAYMENT_TYPES = {
  CREDIT_CARD: 'CREDIT_CARD',
  RTG_CREDIT: 'RTG_CREDIT',
}

const disabledFinanceStores = ['911', '1091', '2911', '913', '1094', '1694', '2913', '902', '1092', '917']

const Billing = ({ shippingAddress, balance }) => {
  const decision = getCreditCardDecision()
  const [billingAddress, setBillingAddress] = useState(shippingAddress)
  const [microformKey, setMicroformKey] = useState(1)
  const dispatch = useDispatch()
  const {
    order,
    isFullPayment,
    partialPaymentValue,
    premiumDelivery,
    premiumDeliveryCharge,
    creditcardPayment,
    financePayment,
  } = useSelector(state => state.orderStatus)
  const financePlans = getFinancePlansFromRegion()
  const synchronyPlan = financePlans.findLast(plan => plan?.financeCode !== 'GENESIS' && balance > plan?.threshold)

  const rtgFinanceDisabled =
    disabledFinanceStores.includes(order?.StoreNumber ?? '') ||
    !!order?.FinancePlan ||
    order?.FinDeposits?.Deposit?.length > 0

  const [selectedPaymentType, setSelectedPaymentType] = useState(rtgFinanceDisabled ? PAYMENT_TYPES.CREDIT_CARD : null)

  let authAmount = 0

  if (premiumDelivery && isFullPayment) {
    authAmount = Number(order?.Balance) + Number(premiumDeliveryCharge?.total ?? 0)
  } else if (isFullPayment) {
    authAmount = Number(order?.Balance)
  } else {
    authAmount = partialPaymentValue
  }

  const minimumDisabled = !isFullPayment && partialPaymentValue < 20

  const creditCardSection = (
    <div id="panel1" role="tabpanel" tabIndex="0">
      {selectedPaymentType === PAYMENT_TYPES.CREDIT_CARD && (
        <>
          <div className={classNames({ accepted: decision })}>
            <BillingAddress address={billingAddress} setBillingAddress={setBillingAddress} />
            <CreditCardForm
              key={microformKey}
              setMicroformKey={() => setMicroformKey(microformKey + 1)}
              onSubmit={creditCardInfo => dispatch(setCreditCardPayment(creditCardInfo))}
              onTimeout={() => {
                navigate('/order/details')
              }}
              authAmount={authAmount}
              minimumDisabled={minimumDisabled}
            />
          </div>
          {minimumDisabled && (
            <>
              <br />
              <ErrorMessage customMessage={{ message: 'Minimum partial payment is $20.' }} />
            </>
          )}
        </>
      )}
    </div>
  )

  const financeSection = (
    <div id="panel2" role="tabpanel" tabIndex="0">
      {selectedPaymentType === PAYMENT_TYPES.RTG_CREDIT && (
        <>
          <RTGCredit
            disableGenesis
            authAmount={Number(balance).toFixed(2)}
            onSubmit={financeInfo => dispatch(setFinancePayment(financeInfo))}
            minimumDisabled={minimumDisabled}
            financePlans={financePlans}
            synchronyPlan={synchronyPlan}
          />
        </>
      )}
    </div>
  )

  const creditCardApplied = creditcardPayment?.authAmount > 0
  const financeApplied = financePayment?.authAmount > 0

  return (
    <>
      <PaymentAmounts
        balance={balance}
        isFullPayment={isFullPayment}
        isPremiumDelivery={premiumDelivery}
        partialPaymentValue={partialPaymentValue}
        premiumDeliveryCharge={premiumDeliveryCharge}
      />
      {/* <ErrorMessage
        customMessage={{ message: 'Cannot continue until a payment has been submitted.', id: 'payment-section' }}
      /> */}
      <div>
        {rtgFinanceDisabled && creditCardSection}
        {!rtgFinanceDisabled && (
          <>
            <TabButtonContainer role="tablist" aria-label="Payment Method">
              <span data-for="credit-disabled" data-tip data-tip-disable={false}>
                <TabButton
                  aria-controls="panel1"
                  aria-selected={false}
                  backgroundColor={colors.primary}
                  data-testid="credit-card"
                  data-tip-disable={!(selectedPaymentType !== PAYMENT_TYPES.CREDIT_CARD && authAmount > 0)}
                  id="tab1"
                  onClick={() => {
                    if (selectedPaymentType !== PAYMENT_TYPES.CREDIT_CARD && !financeApplied) {
                      // dispatch(setPartialPayment({ partialPaymentValue: '0', isFullPayment: true }))
                      dispatch(setCreditCardPayment(initialCreditcardPayment))
                      dispatch(setFinancePayment(initialFinancePayment))
                      setSelectedPaymentType(PAYMENT_TYPES.CREDIT_CARD)
                    }
                  }}
                  role="tab"
                  tabIndex={0}
                  type="button"
                >
                  <span className="payment-type-text">Credit Card</span>
                </TabButton>
              </span>
              {financeApplied && (
                <ReactToolTip place="top" type="dark" effect="float" id="credit-disabled">
                  Must remove financing to use another payment method.
                </ReactToolTip>
              )}
              <span data-for="rtg-credit-disabled" data-tip data-tip-disable={false}>
                <TabButton
                  aria-selected={false}
                  aria-controls="panel2"
                  backgroundColor={colors.darkerGrey}
                  data-testid="rooms-to-go-button"
                  id="tab2"
                  onClick={() => {
                    if (selectedPaymentType !== PAYMENT_TYPES.RTG_CREDIT && !creditCardApplied) {
                      // dispatch(setPartialPayment({ partialPaymentValue: '0', isFullPayment: true }))
                      dispatch(setCreditCardPayment(initialCreditcardPayment))
                      dispatch(setFinancePayment(initialFinancePayment))
                      setSelectedPaymentType(PAYMENT_TYPES.RTG_CREDIT)
                    }
                  }}
                  role="tab"
                  tabIndex={0}
                  type="button"
                  disabled={Number(balance) === 0 || rtgFinanceDisabled}
                >
                  <span>Rooms To Go Credit</span>
                </TabButton>
              </span>
              {rtgFinanceDisabled && (
                <ReactToolTip place="top" type="dark" effect="float" id="rtg-credit-disabled">
                  {`Financing not available for store ${order?.StoreNumber}`}
                </ReactToolTip>
              )}
              {creditCardApplied && (
                <ReactToolTip place="top" type="dark" effect="float" id="rtg-credit-disabled">
                  Must remove credit card to use another payment method.
                </ReactToolTip>
              )}
            </TabButtonContainer>
            {selectedPaymentType === PAYMENT_TYPES.CREDIT_CARD && creditCardSection}
            {selectedPaymentType === PAYMENT_TYPES.RTG_CREDIT && financeSection}
          </>
        )}
      </div>
    </>
  )
}

Billing.propTypes = {
  balance: number,
  shippingAddress: object,
}

export default Billing
