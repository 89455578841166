import React from 'react'
import { useDispatch } from 'react-redux'
import { any, bool, number } from 'prop-types'
import styled from 'styled-components'
import { fonts, breakPoints, colors } from '@constants/styles'
import { setPartialPayment } from '@redux/modules/orderStatus'

const PaymentNotes = '*Asterisks indicate required fields in the form below'

const RequiredLabel = styled.p`
  margin: 0 0 0.5rem 0;
  font-weight: 600;
  @media only screen and (max-width: ${breakPoints.small}) {
    font-size: ${fonts.txtSmall};
    margin: 0.5em 0 1em 0;
  }
`

const PayBalanceWrapper = styled.section`
  display: flex;
  min-height: 110px;

  label {
    display: flex;
    margin-right: 10px;

    span {
      padding: 3px;
      max-width: 161px;
    }
  }

  .radio-title,
  .delivery-charge {
    text-transform: uppercase;
    font-size: ${fonts.txtSmall};
    font-weight: 600;
  }

  .delivery-charge {
    font-size: ${fonts.txtMediumUp};
  }
  .payment-notification {
    margin-top: 5px;
    display: flex;
  }

  .payment-input {
    background-color: ${colors.lighterGrey};
    border-color: ${colors.lightGrey} !important;
    width: 140px;
    height: 40px;
  }

  @media screen and (max-width: ${breakPoints.small}) {
    label {
      margin: 0;
    }
  }
`

const PaymentAmounts = ({ balance, isFullPayment, isPremiumDelivery, partialPaymentValue, premiumDeliveryCharge }) => {
  const dispatch = useDispatch()
  const newBalance = isPremiumDelivery ? (balance + Number(premiumDeliveryCharge?.total)).toFixed(2) : balance
  return (
    <>
      {(balance > 0 || (isPremiumDelivery && premiumDeliveryCharge.total > 0)) && (
        <div className="grid-x">
          <div className="cell small-12 medium-12 large-4">
            <RequiredLabel>{PaymentNotes}</RequiredLabel>
            <PayBalanceWrapper>
              <label htmlFor="pay-balance">
                <input
                  type="radio"
                  id="pay-balance"
                  name="payment-amount"
                  onChange={() => {
                    if (document.getElementById('credit-try-again')) {
                      document.getElementById('credit-try-again').click()
                    }
                    dispatch(setPartialPayment({ isFullPayment: true, partialPaymentValue: null }))
                  }}
                  checked={isFullPayment}
                />
                <span>
                  <p className="radio-title">pay balance</p>
                  <p className="delivery-charge">${newBalance}</p>
                </span>
              </label>
              {Number(newBalance) > 99.99 && (
                <label htmlFor="pay-other-amount">
                  <input
                    type="radio"
                    id="pay-other-amount"
                    name="payment-amount"
                    onChange={() => {
                      if (document.getElementById('credit-try-again')) {
                        document.getElementById('credit-try-again').click()
                      }
                      dispatch(setPartialPayment({ isFullPayment: false, partialPaymentValue: null }))
                    }}
                    checked={!isFullPayment}
                  />
                  <span>
                    <p className="radio-title">pay other amount*</p>
                    <input
                      className="payment-input"
                      type="text"
                      value={partialPaymentValue || ''}
                      onChange={event => {
                        if (document.getElementById('credit-try-again')) {
                          document.getElementById('credit-try-again').click()
                        }
                        dispatch(setPartialPayment({ isFullPayment: false, partialPaymentValue: event.target.value }))
                      }}
                      onClick={event =>
                        dispatch(setPartialPayment({ isFullPayment: false, partialPaymentValue: event.target.value }))
                      }
                    />
                    <div className="payment-notification">
                      <p>*</p>
                      <p> Order will not be delivered until balance is paid in full.</p>
                    </div>
                  </span>
                </label>
              )}
            </PayBalanceWrapper>
          </div>
        </div>
      )}
    </>
  )
}

PaymentAmounts.propTypes = {
  balance: number,
  isFullPayment: bool,
  isPremiumDelivery: bool,
  partialPaymentValue: any,
  premiumDeliveryCharge: any,
}

export default PaymentAmounts
